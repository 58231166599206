@import '../main-layout/styles/theme.scss';

.radio-button-container {
    background: $light-background;
    width: 128px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-align: center;

    &:hover {
        border: 1px solid $primary-color-op5;
        font-weight: bold;
        color: $primary-color-op8;
    }
}
