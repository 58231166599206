@import '../main-layout/styles/theme.scss';

.login-page-container {
    flex-grow: 1;
    max-width: 460px;
    min-width: $small;
    vertical-align: center;
}

.login-card-container {
    padding: 2em;
}

.login-title {
    margin-bottom: 1em !important;
}
